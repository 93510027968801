import {
    AddVouchersToUserDto,
    CreateUserDto,
    GetUserDto,
    TenantSettingsDto,
    UserVoucherInfoDTO,
    UserVoucherListDTO,
} from '@icp/mbp-cirklo-api-client';
import { createAction, props } from '@ngrx/store';

const prefix = `[User]`;

export const getCirkloUser = createAction(`${prefix} Get Cirklo User`);
export const getCirkloUserSuccess = createAction(
    `${prefix} Get Cirklo User Success`,
    props<{ payload: GetUserDto | null }>(),
);
export const getCirkloUserFailed = createAction(`${prefix} Get Cirklo User Failed`, props<{ error: string }>());

export const createCirkloUser = createAction(`${prefix} Create Cirklo User`, props<{ payload: CreateUserDto }>());
export const createCirkloUserSuccess = createAction(
    `${prefix} Create Cirklo User Success`,
    props<{ payload: GetUserDto }>(),
);
export const createCirkloUserFailed = createAction(`${prefix} Create Cirklo User Failed`, props<{ error: string }>());

export const listCirkloVouchers = createAction(`${prefix} List Cirklo Vouchers`);
export const listCirkloVouchersSuccess = createAction(
    `${prefix} List Cirklo Vouchers Success`,
    props<{ payload: UserVoucherListDTO }>(),
);
export const listCirkloVouchersFailed = createAction(
    `${prefix} List Cirklo Vouchers Failed`,
    props<{ error: string }>(),
);

export const addCirkloVoucher = createAction(
    `${prefix} Add Cirklo Voucher`,
    props<{ payload: AddVouchersToUserDto }>(),
);
export const addCirkloVoucherSuccess = createAction(
    `${prefix} Add Cirklo Voucher Success`,
    props<{ payload: UserVoucherInfoDTO[] }>(),
);
export const addCirkloVoucherFailed = createAction(`${prefix} Add Cirklo Voucher Failed`, props<{ error: string }>());

export const confirmDeleteCirkloVoucher = createAction(
    `${prefix} Confirm Delete Cirklo Voucher`,
    props<{
        voucherId: string;
    }>(),
);
export const deleteCirkloVoucher = createAction(`${prefix} Delete Cirklo Voucher`, props<{ voucherId: string }>());
export const deleteCirkloVoucherSuccess = createAction(
    `${prefix} Delete Cirklo Voucher Success`,
    props<{
        voucherId: string;
    }>(),
);
export const deleteCirkloVoucherFailed = createAction(
    `${prefix} Delete Cirklo Voucher Failed`,
    props<{
        error: string;
    }>(),
);

export const getTenantSettings = createAction('[Cirklo] Get tenant setting');
export const getTenantSettingsSuccess = createAction(
    '[Cirklo] Get tenant setting success',
    props<{ payload: TenantSettingsDto }>(),
);
export const getTenantSettingsFailed = createAction('[Cirklo] Get tenant setting failed', props<{ error: string }>());
